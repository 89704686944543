import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartActivePower from '../../aegeon/charts/ChartActivePower';
import ChartIndex from '../../aegeon/charts/ChartIndex';
import CustomTooltip from '../../aegeon/CustomTooltip';
import QueryField from '../../aegeon/query/QueryField';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const moment = require('moment');

const startDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(15, 'days');
const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const filter = { startDate: startDate, endDate: endDate };

const startDateOneYear = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'year');
const filterOneYear = { startDate: startDateOneYear, endDate: endDate };

const LineCardDetails = ({ setDataCallback }) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const [dataSources, setDataSources] = useState({});
  const setDataSourcesCallback = (childData, source) => {
    if (childData && !dataSources[source]) {
      setDataSources({ ...dataSources, [source]: childData });
    }
  };

  useEffect(() => {
    setDataCallback(dataSources);
  }, [dataSources, setDataCallback]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={12} key="1">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.power.detailledTitle')}</Typography>
            {<CustomTooltip title={t('devices.power.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.power.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }} >
            <TimeseriesField source={"active_power"} filter={filter}>
              <QueryField query_name='getDeviceContract' >
                <ChartActivePower
                  isWeekendsHighlight={true}
                  isNightsHighlight={true}
                  isDetailedCard={true}
                  setLineDataCallback={setDataSourcesCallback}
                  isZoom={true}
                />
              </QueryField>
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"index"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.index.title')}</Typography>
            {<CustomTooltip title={t('devices.index.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.index.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={"index"} filter={filterOneYear}>
              <ChartIndex
                line={true}
                stacked={true}
                type="index"
                setDataCallback={setDataSourcesCallback}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"index_substracted"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.index_substracted.title')}</Typography>
            {<CustomTooltip title={t('devices.index_substracted.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.index.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={"index"} filter={filterOneYear}>
              <ChartIndex
                line={true}
                stacked={true}
                type="index_substracted"
                conversionButton={true}
                monthAgg={true}
                setDataCallback={setDataSourcesCallback}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"energy"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.energy.title')}</Typography>
            {<CustomTooltip title={t('devices.energy.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.energy.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={"energy"} filter={filterOneYear}>
              <ChartIndex
                line={true}
                stacked={false}
                type="energy"
                setDataCallback={setDataSourcesCallback}
                conversionButton={true}
                monthAgg={true}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
    </Grid2>
  )
};

export default LineCardDetails;

