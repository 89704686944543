import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { default as React, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartIndex from '../../aegeon/charts/ChartIndex';
import ChartMonotone from '../../aegeon/charts/ChartMonotone';
import CustomTooltip from '../../aegeon/CustomTooltip';
import QueryField from "../../aegeon/query/QueryField";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";

const MonotoneCardDetails = ({ setDataCallback, setSubtitleCallback, subtitle }) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const moment = require('moment');
  const startDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(7, 'days');
  const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDate, endDate: endDate };

  const startDateOneYear = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'year');
  const filterOneYear = { startDate: startDateOneYear, endDate: endDate };

  const [dataSources, setDataSources] = useState({});
  const setDataSourcesCallback = (childData, source) => {
    if (childData && !dataSources[source]) {
      setDataSources({ ...dataSources, [source]: childData });
    }
  };

  useEffect(() => {
    setDataCallback(dataSources);
  }, [dataSources, setDataCallback]);

  const handleSetSubtitleCallback = (childData) => {
    if (childData) {
      setSubtitleCallback && setSubtitleCallback(childData)
    }
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={12} key="1">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.monotone.detailledTitle')}</Typography>
            {<CustomTooltip title={t('devices.monotone.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {subtitle}
          </div>
          <div style={{ width: "100%", height: "550px" }} >
            <TimeseriesField source="active_power_monotone" filter={filter}>
              <QueryField query_name='getDeviceContract' >
                <div style={{ width: "100%", height: "550px" }} >
                  <ChartMonotone isDetailedCard={true} setSubtitleCallback={handleSetSubtitleCallback} setMonotoneDataCallback={setDataSourcesCallback} />
                </div>
              </QueryField>
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"maximum_power"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.maximum_power.title')}</Typography>
            {<CustomTooltip title={t('devices.maximum_power.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.maximum_power.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <QueryField query_name="getDeviceContract">
              <TimeseriesField source={"maximum_power"} filter={filterOneYear}>
                <ChartIndex
                  line={true} stacked={false}
                  type="maximum_power"
                  setDataCallback={setDataSourcesCallback}
                />
              </TimeseriesField>
            </QueryField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"time_of_use"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.time_of_use.title')}</Typography>
            {<CustomTooltip title={t('devices.time_of_use.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.time_of_use.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={"time_of_use"} filter={filterOneYear}>
              <ChartIndex
                line={true}
                stacked={true}
                type="time_of_use"
                conversionButton={true}
                setDataCallback={setDataSourcesCallback}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"overrun_duration"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.overrun_duration.title')}</Typography>
            {<CustomTooltip title={t('devices.overrun_duration.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.overrun_duration.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={"overrun_duration"} filter={filterOneYear}>
              <ChartIndex
                line={true} stacked={false}
                type="overrun_duration"
                setDataCallback={setDataSourcesCallback}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={6} key={"overrun_quadratic"}>
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.overrun_quadratic.title')}</Typography>
            {<CustomTooltip title={t('devices.overrun_quadratic.tooltip_text')} icon={<InfoTooltipIcon />} />}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.overrun_quadratic.subtitle')}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <TimeseriesField source={"overrun_quadratic"} filter={filterOneYear}>
              <ChartIndex
                line={true} stacked={false}
                type="overrun_quadratic"
                setDataCallback={setDataSourcesCallback}
              />
            </TimeseriesField>
          </div>
        </Card>
      </Grid2 >
    </Grid2 >
  )
};

export default MonotoneCardDetails;

