import { Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import InfoTooltipIcon from '../../../assets/InfoTooltipIcon';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import CustomTooltip from '../../aegeon/CustomTooltip';
import AnalysisCardDetails from '../../aegeon/cards/AnalysisCardDetails';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import DataComparisonAnalysisCardDetails from './DataComparisonAnalysisCardDetails';

const AnalysisTextDetails = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const { data, isLoading } = useQueryContext(props);
  const { record, setRecord } = useContext(RecordContext);

  useEffect(() => {
    if (data && !isLoading) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoading]);

  const setDataCallback = (childData) => {
    if (childData && !props.dataAnalysis) {
      props.setDataAnalysis(childData)
    }
  }

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={6} medium={6} big={6} key="1">
        <Card className={globalClasses.DetailCard}>
          <AnalysisCardDetails analysisClass="DEVICE" analysisType="METER_COMMISSIONING" setDataCallback={setDataCallback} />
        </Card>
      </Grid2>
      <Grid2 item="true" small={6} medium={6} big={6} key="2">
        <Card className={globalClasses.DetailCard}>
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t('devices.analyse.comparison.title')}</Typography>
            <CustomTooltip title={t('devices.analyse.comparison.tooltip_text')} icon={<InfoTooltipIcon />} />
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t('devices.analyse.comparison.subtitle')}
          </div>
          <div className={globalClasses.DetailCardGraphDouble}>
            <DataComparisonAnalysisCardDetails />
          </div>
        </Card>
      </Grid2>
    </Grid2>
  )
};


const AnalysisTextDetailsWithContext = (props) => {
  const t = useTranslate();
  const title = t('devices.analyse.title');
  const [dataAnalysis, setDataAnalysis] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (dataAnalysis !== undefined) {
      setDisabled(false)
    }
  }, [dataAnalysis])

  const handleExport = (name) => {
    var sheets_data = [];
    if (dataAnalysis !== null) {
      dataAnalysis.map((item, index) => {
        const date = new Date(item.created_at).toLocaleDateString(localStorage.getItem("language"), { year: 'numeric', month: 'long', day: 'numeric' })
        sheets_data.push({ sheetName: index + " - " + date, data: [["Date de l'analyse", date], ["Résultat de l'analyse", item.text]] });
      }
      )
      export_row_to_excel(sheets_data, `${t('resources.analyse.export_name')}${name}.xlsx`);
    };
  };
  return (
    <DetailsShow title={title} resource="devices" lessMargin={true} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />} >
      <QueryField query_name='getDevice'>
        <AnalysisTextDetails setDataAnalysis={setDataAnalysis} dataAnalysis={dataAnalysis} />
      </QueryField>
    </DetailsShow>
  )
}

export default AnalysisTextDetailsWithContext;

